/*@font-face {*/
/*  font-family: 'LXGW WenKai GB';*/
/*  src: url('assets/700.woff2') format('woff2');*/
/*  font-weight: 700;*/
/*  font-style: normal;*/
/*  font-display: block;*/
/*}*/

/*@font-face {*/
/*  font-family: 'LXGW WenKai GB';*/
/*  src: url('assets/b1.ttf') format('truetype');*/
/*  font-weight: bold;*/
/*  font-style: normal;*/
/*  font-display: block;*/
/*}*/
/*@font-face {*/
/*  font-family: 'LXGW WenKai GB';*/
/*  src: url('assets/400.ttf') format('truetype');*/
/*  font-weight: 400;*/
/*  font-style: normal;*/
/*  font-display: block;*/
/*}*/
@font-face {
  font-family: 'LXGW WenKai GB';
  /*src: url('https://img.lblssl.cn/other/font/newFont.ttf') format('truetype');*/
  src: url('https://img.lblssl.cn/other/font/font0829.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'cuteFont';
  src: url('assets/cute1.ttf') format('truetype');
  /*src: url('https://img.lblssl.cn/other/font/font0829.woff2') format('woff2');*/
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'make';
  /*src : url('https://img.lblssl.cn/other/font/make.ttf') format('truetype');*/
  src: url('https://img.lblssl.cn/other/font/make.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
.ant-notification {
  font-family: 'LXGW WenKai GB', sans-serif !important;
}

.ant-message {
  font-family: 'LXGW WenKai GB', sans-serif !important;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'LXGW WenKai GB', sans-serif!important;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5; /* 或使用具体数值，如 24px */
}


html{
  font-size: 14px!important;
}


*{
  /*--font-text-override: 'LXGW WenKai GB';*/
  box-sizing: border-box!important;

}








