@import "../../styles/global.less";
.mainBox{
  width: 100%;
  height: 100vh;
  background: #f1f3f6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.iconStyle{
  width: 200px;
  height: 200px;
}
.backBtn{

  color: @main-theme-color;
  text-decoration: underline;
  font-size: 20px;
  transition: all .3s ease-in-out;
  &:hover{
    cursor: pointer;
    transition: all .3s ease-in-out;
    color: @main-theme-color;
  }
}
.tip{
  margin:15px 0;
  font-size: 16px;
}