@import "global.less";
.ant-table-body {
  /* 修改滚动区域的滚动条样式 */

  &::-webkit-scrollbar {
    width: 3px !important; /* 水平滚动条的宽度 */
    height: 3px !important; /* 垂直滚动条的高度 */
  }

  &::-webkit-scrollbar-track {
    background: #a10b0b !important; /* 滚动条轨道的背景色 */
  }

  &::-webkit-scrollbar-thumb {
    background: #cb1616 !important; /* 滚动条实际可拖动部分的背景色 */
    border-radius: 6px; /* 滚动条的圆角 */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555 !important; /* 滚动条实际可拖动部分的背景色（鼠标悬浮时） */
  }
}

.ant-message-notice-content {
  padding: 0 !important;
  border-radius: 3px;
  overflow: hidden;
}

//失败弹窗(error)
.ant-message-custom-content.ant-message-error {
  color: white;
  background: linear-gradient(90deg, #ff4b2b, #ff416c) !important;
  padding: 6px 10px;
  //display: flex;
  //align-items: center;
}

.ant-message-error .anticon {
  color: #fff !important; /* 替换成你想要的颜色 */
  //width: 20px;
  //height: 20px;
  //
  //svg{
  //  display: none;
  //}
  //background: url("assets/img/errorIcon.svg")!important;
  //background-repeat: no-repeat!important;
  //background-size: cover!important;
  //background-position: center center!important;
}

//成功弹窗(success)
.ant-message-custom-content.ant-message-success {
  //background-color: #39c5bb;
  background-color: #06b650;
  color: white;
  padding: 8px 10px;
  //display: flex;
  //align-items: center;
}
.ant-message-success .anticon {
  color: #fff !important; /* 替换成你想要的颜色 */
  //width: 20px;
  //height: 20px;
  //svg{
  //  display: none;
  //}
  //background: url("assets/img/successIcon.svg")!important;
  //background-repeat: no-repeat!important;
  //background-size: cover!important;
  //background-position: center center!important;
}

//信息(info)
.ant-message-custom-content.ant-message-info {
  background-color: @main-theme-color;
  color: white;
  padding: 6px 10px;
  //display: flex;
  //align-items: center;
}
.ant-message-info .anticon {
  //width: 20px;
  //height: 20px;
  //svg{
  //  display: none;
  //}
  //background: url("assets/img/infoIcon.svg")!important;
  //background-repeat: no-repeat!important;
  //background-size: cover!important;
  //background-position: center center!important;
  color: #fff !important; /* 替换成你想要的颜色 */
}

//waring
.ant-message-custom-content.ant-message-warning {
  background-color: orange;
  color: white;
  padding: 6px 10px;
  //display: flex;
  //align-items: center;
}
.ant-message-warning  .anticon {
  //width: 20px;
  //height: 20px;
  //svg{
  //  display: none;
  //}
  //background: url("assets/img/warningIcon.svg")!important;
  //background-repeat: no-repeat!important;
  //background-size: cover!important;
  //background-position: center center!important;
  color: #fff !important; /* 替换成你想要的颜色 */
}

//修改modal背景mask
 .ant-modal-mask{
  backdrop-filter: blur(12px) !important;
  -webkit-backdrop-filter: blur(12px) !important;
  transform: translateZ(0) !important;
  background: rgba(255, 255, 255, 0.85) !important;
  display: block!important;
}