.main-box {
  border: 1px solid red;
}
.header {
  padding: 15px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
