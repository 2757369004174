
/* 清除内外边距 */
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote,
dl, dt, dd, ul, ol, li,
pre,
fieldset, lengend, button, input, textarea,
th, td {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* 应用于所有元素 */
  //cursor: url('https://img.lblssl.cn/other/mouse/default.cur'), default !important;
}

/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */

//@font-face {
//  font-family: 'lblFont';
//  src: url('https://img.lblssl.cn/other/font/font.woff2') format('woff2'),
//  url('https://img.lblssl.cn/other/font/fontawesome-webfont.af7ae505.woff2') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}


h1 { font-size: 18px; /* 18px / 12px = 1.5 */ }
h2 { font-size: 16px; }
h3 { font-size: 14px; }
h4, h5, h6 { font-size: 100%; }

address, cite, dfn, em, var, i{ font-style: normal; } /* 将斜体扶正 */
b, strong{ font-weight: normal; } /* 将粗体扶细 */
code, kbd, pre, samp, tt { font-family: "Courier New", Courier, monospace; } /* 统一等宽字体 */
small { font-size: 12px; } /* 小于 12px 的中文很难阅读，让 small 正常化 */

/* 重置列表元素 */
ul, ol { list-style: none; }

/* 重置文本格式元素 */
a { text-decoration: none; color: #666;}


/* 重置表单元素 */
legend { color: #000; } /* for ie6 */
fieldset, img { border: none; }
button, input, select, textarea {
  font-size: 100%; /* 使得表单元素在 ie 下能继承字体大小 */
}

/* 重置表格元素 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 重置 hr */
hr {
  border: none;
  height: 1px;
}
.clearFix::after{
  content:"";
  display: block;
  clear:both;
}
/* 让非ie浏览器默认也显示垂直滚动条，防止因滚动条引起的闪烁 */
html { overflow-y: scroll; }

a:link:hover{
  color : rgb(79, 76, 212) !important;
  text-decoration: underline;
}

/* 清除浮动 */
.clearfix::after {
  display: block;
  height: 0;
  content: "";
  clear: both;
  visibility: hidden;
}
::-webkit-scrollbar{
  width: 5px;
  height: 10px;
}
::-webkit-scrollbar-thumb{
  background-color: #49b1f5!important;
  background-image: linear-gradient(45deg,hsla(0,0%,100%,.4) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.4) 0,hsla(0,0%,100%,.4) 75%,transparent 0,transparent) !important;
  border-radius: 10px;
}
::-webkit-scrollbar-track{
  border-radius: 10px!important;
}
.App{
  /*border:1px solid red;*/
  height: 100vh;
}


/* */
/* 修改滚动条轨道的样式 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px
}

/* 修改滚动条轨道的颜色 */
::-webkit-scrollbar-track {
  background-color: rgba(73,177,245,.2) !important;
}

/* 修改滚动条滑块的样式 */
::-webkit-scrollbar-thumb {
  background-color: #49b1f5!important;
  background-image: linear-gradient(45deg,hsla(0,0%,100%,.4) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.4) 0,hsla(0,0%,100%,.4) 75%,transparent 0,transparent) !important;
  border-radius: 1em;
}
.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #49b1f5!important;
  background-image: linear-gradient(45deg, hsla(0, 0%, 100%, .4) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .4) 0, hsla(0, 0%, 100%, .4) 75%, transparent 0, transparent) !important;
  border-radius: 5px!important; /* 设置滑块圆角 */
}

/* 鼠标悬停在滑块上的样式 */
/*::-webkit-scrollbar-thumb:hover {*/
/*  background-color: #555!important; !* 设置悬停颜色 *!*/
/*}*/

//body.modal-open {
//  height: 100vh;
//  overflow: hidden;
//}


.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  text-align: center;
  vertical-align: middle;
}
