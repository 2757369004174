.mainBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.mainBox .content {
  padding-left: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.mainBox .content .content-l {
  transition: all 0.3s ease-in-out;
  padding-right: 5px;
  overflow-y: auto;
  height: 100%;
}
.mainBox .content .content-l .contentLUl {
  border-radius: 12px;
  background: #fff;
  width: 100%;
  display: grid;
  gap: 15px;
  transition: all 0.3s ease-in-out;
  padding: 5px;
}
.mainBox .content .content-l .contentLUl * {
  box-sizing: border-box;
}
.mainBox .content .content-l .contentLUl .contentLLi {
  overflow: hidden;
  border: 1px solid #d8d1d1;
  padding: 8px 16px;
  border-radius: 12px;
  height: 95px;
  opacity: 0;
  animation: slide-in 0.3s linear 0s forwards;
  /* 重要：使用 forwards 保持结束状态 */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  flex-shrink: 0;
  transition: all 0.3s ease-in-out;
}
.mainBox .content .content-l .contentLUl .contentLLi:hover {
  border: 1px solid #2e51b3;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  box-shadow: 0 12px 20px #1366ec1f;
}
.mainBox .content .content-l .contentLUl .contentLLi:hover .liTop {
  transition: all 0.3s ease-in-out;
  color: #2e51b3;
}
.mainBox .content .content-l .contentLUl .contentLLi .liTop {
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  color: #4c4948;
  width: 100%;
}
.mainBox .content .content-l .contentLUl .contentLLi .liTop span {
  margin: 0;
}
.mainBox .content .content-l .contentLUl .contentLLi .liTop .liLeftTop {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* 设置盒子垂直排列 */
  text-overflow: ellipsis;
  /* 超出部分显示省略号 */
  -webkit-line-clamp: 2;
  /* 设置最大行数为 2 */
  line-height: 1.5;
  /* 设置行高，确保多行效果 */
  max-height: calc(1.5em * 2);
  /* 限制高度为两行 */
}
.mainBox .content .content-l .contentLUl .contentLLi .liBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
}
.mainBox .content .content-l .contentLUl .contentLLi .liBottom span {
  margin: 0;
}
.mainBox .content .content-l .contentLUl .contentLLi .liTag {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  max-width: 150px;
  margin-right: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.mainBox .content .content-l .contentLUl .contentLLi .liTag :global .ant-tag {
  display: flex;
  align-items: center;
}
.mainBox .content .content-l .contentLUl .contentLLi .liTag :global .ant-tag > svg {
  margin-right: 3px;
}
.mainBox .content .content-r {
  position: relative;
  background: #fff;
  transition: all 0.3s ease-in-out;
  min-height: 100%;
  overflow-y: auto;
  height: 100%;
}
.mainBox .content .content-r .myWidth {
  width: 100% !important;
  padding: 10px;
}
.mainContent {
  display: flex;
  height: calc(100vh - 0px);
  width: 100%;
}
.headerTop {
  padding: 0 15px;
  width: 100%;
  background: #f7f9fe;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.answerTop {
  margin-top: 10px;
  border-radius: 8px;
  overflow: hidden;
  height: 50px;
  border-bottom: 1px solid #f4ecec;
  display: flex;
  box-shadow: 0 0.5rem 1rem rgba(18, 38, 63, 0.05);
  position: relative;
  margin-left: 10px;
  width: calc(100% - 20px);
  padding-left: 15px;
  background: #d4d7f1;
  justify-content: flex-start;
  align-items: center;
}
.answerTop:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 8px;
  height: 100%;
  background: #2e51b3;
}
.answerTopTitle {
  font-size: 20px;
  font-weight: bold;
  color: #4c4948;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadMore {
  width: 100% !important;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  margin-left: 50%;
}
.loadMore:hover {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  color: #2e51b3;
}
.iconStyles {
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}
.iconStyles * {
  transition: all 0.3s ease-in-out;
}
.iconStyles:hover {
  cursor: pointer;
  scale: 1.05;
  transition: all 0.3s ease-in-out;
  box-shadow: 2px 2px 2px #000000;
}
.iconStyles:hover path {
  fill: #2e51b3;
}
.iconStyles:hover * {
  transition: all 0.3s ease-in-out;
}
.activeLi {
  background-image: url("./img/bg.jpg");
  background-size: cover;
  background-position: right bottom;
  transition: all 0.15s ease-in-out;
  box-shadow: 0 12px 20px #1366ec1f;
}
.activeLi .liLeftTop {
  color: #2e51b3;
}
.contentRBottom {
  padding: 0 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: absolute;
  z-index: 10;
  bottom: 0;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f1f1f8;
}
.beforeBtn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.lastBtn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.settingIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 3px;
  transition: all 0.3s ease-in-out;
}
.settingIcon:hover {
  transition: all 0.3s ease-in-out;
  background: #f4f4f5;
}
.settingIcon:hover svg {
  font-size: 26px;
}
.settingIcon:hover svg path {
  fill: #2e51b3;
}
.settingIcon svg {
  font-size: 26px;
}
.settingIcon svg path {
  fill: #4c4948;
}
.settingUl {
  color: #4c4948;
}
.settingUl .settingLi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
}
.settingUl .settingLi .configName {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
.settingUl .settingLi .boxStyleSettting {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.settingUl .settingLi .boxStyleSettting div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.pageStyles {
  font-size: 30px;
  color: #4c4948;
}
.rMainContent {
  height: calc(100% - 50px);
  overflow-y: auto;
}
.searchInput {
  width: 300px;
  margin-right: 15px;
}
.bottomL {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.swicthStyle {
  transition: all 0.3s ease-in-out;
}
.swicthStyle:hover {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  scale: 1.1;
}
.tipL {
  font-size: 14px;
  color: #4c4948;
}
.tipL .tip {
  margin-top: 3px;
  display: flex;
  flex-direction: column;
}
.myRadio {
  width: 100% !important;
  transition: all 0.3s ease-in-out !important;
  height: 100%;
  max-height: 100% !important;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  /* 隐藏溢出 */
  align-items: flex-start;
  z-index: 1;
  padding-top: 5px;
  flex-shrink: 0;
}
.myRadio :global .ant-badge {
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.myRadio :global .ant-badge .ant-badge-count {
  top: 12px;
}
.myRadio > span {
  margin: 3px 10px;
}
.myRadio svg {
  width: 14px !important;
  height: 14px !important;
  margin-right: 5px;
}
.myRadio :global .ant-radio-button-wrapper-checked svg path {
  fill: #fff;
}
.myRadio label {
  padding: 0 5px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 12px !important;
}
.myRadio label > span {
  margin: 0 10px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.myRadio label::before {
  display: none !important;
}
.radioSty {
  background: #f7f9fe;
  position: absolute;
  width: calc(50% - 10px);
  left: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;
  box-shadow: 0 0.5rem 1rem #12263f0d;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}
.radioSmall {
  height: 65px;
  overflow: hidden;
  /* 隐藏超出内容 */
}
.radioBig {
  height: auto;
  flex-wrap: wrap;
  /* 保证内容可以换行 */
  width: calc(50% - 25px);
  padding-bottom: 10px;
}
.showMore {
  position: absolute;
  z-index: 101;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.showZ {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  background-image: linear-gradient(to right, rgba(242, 247, 249, 0) 0%, rgba(242, 247, 249, 0.8) 10%, #c8c8c8 100%);
  padding: 0 10px;
  color: #333;
  box-shadow: 0 8px 16px -4px #2c2d300c;
}
.showS {
  color: #333;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  height: 20px;
}
.outBox {
  margin-top: 65px;
  width: 100%;
  height: calc(100% - 70px);
  overflow-y: auto;
  position: relative;
  background: #fff;
  border-radius: 12px;
}
.modalStyles {
  width: 100%;
}
.modalStyles :global .ant-modal {
  width: 100% !important;
}
@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translate(-15px, 30px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
.editOutBox {
  margin-bottom: 3px;
  background: #fff;
  border-radius: 8px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.editOutBox:hover {
  color: #2e51b3;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.editOutBox:hover svg {
  fill: #2e51b3;
}
.loadingStyle {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.liLeftTopR {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.vipStyle {
  position: absolute;
  right: 0;
  top: 0;
}
