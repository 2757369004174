.line-chart {
  padding-top: 15px;
  width: 100% !important;
  height: 100% !important;
}
.line-chart > div {
  width: 100% !important;
}
.line-chart > div canvas {
  width: 100% !important;
}
