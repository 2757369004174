.all-background-ge {
  background: #fff;
  background-image: linear-gradient(90deg, rgba(37, 82, 110, 0.05) 3%, transparent 0), linear-gradient(1turn, rgba(37, 82, 110, 0.05) 3%, transparent 0);
  background-size: 20px 20px;
  background-position: 50%;
}
.animate-in {
  opacity: 0;
  transform: translate(0, 50px);
  animation-name: fadeIn;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.animate-in-img {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    -ms-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
.lv_10 {
  font-size: 12px;
  padding: 0 3px;
  border-radius: 5px;
  margin-right: 8px;
  background: linear-gradient(to left, #ff4500, orange, gold, #90ee90, cyan, #1e90ff, #9370db, hotpink, #ff4500);
  background-size: 110vw;
  color: #fff;
  animation: rainbowan 60s linear infinite;
  -webkit-animation: rainbowan 60s linear infinite;
  -moz-animation: rainbowan 60s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes rainbowan {
  to {
    background-position: -2000vw;
  }
}
@-webkit-keyframes rainbowan {
  to {
    background-position: -2000vw;
  }
}
.animeinfo {
  border-radius: 0.3rem;
  border: 1px solid #ff6a6a1a;
  padding: 0 0.5rem;
  vertical-align: top;
  font-size: 0.75rem;
  word-break: keep-all;
  display: inline-block;
  line-height: 1.2rem;
  vertical-align: baseline;
  font-weight: unset;
  color: rgba(12, 25, 50, 0.7);
  vertical-align: middle;
}
.clear-mask {
  will-change: transform;
  /* 添加性能优化 */
  animation: blur-to-clear 1.8s cubic-bezier(0.62, 0.21, 0.25, 1) 0s 1 normal backwards running, scale 1.5s cubic-bezier(0.62, 0.21, 0.25, 1) 0s 1 both;
}
@keyframes blur-to-clear {
  0% {
    filter: blur(50px);
    opacity: 1;
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes scale {
  0% {
    transform: scale(1.5) translateZ(0);
    opacity: 0;
  }
  to {
    transform: scale(1) translateZ(0);
    opacity: 1;
  }
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 0ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in;
}
.init-route-style {
  display: none;
}
.init-route-style.show {
  display: block;
}
.animated-div {
  animation: fadeInUp 0.5s ease-out forwards;
  /* 应用动画 */
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(25px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.noloaded {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px);
}
.loaded {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  animation: fadeOut 0.6s ease forwards;
  /* 保持动画效果 */
}
@keyframes fadeOut {
  0% {
    z-index: 10;
    /* 初始 z-index */
    backdrop-filter: blur(10px);
  }
  50% {
    backdrop-filter: blur(6px);
    /* 中间状态 */
  }
  100% {
    z-index: -10;
    /* 只在动画结束时改变 z-index */
    backdrop-filter: blur(0px);
  }
}
