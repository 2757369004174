@import "../../../../styles/global.less";

.footer-main {
  padding-top: 15px;
  height: 80px;
  //border:1px solid red;
  //height: 250px;
  width: 100% !important;
  color: #fff !important;
  //backdrop-filter: blur(10px);
  position: relative;
  -webkit-backdrop-filter: blur(10px);
  //background-color: rgba(255, 255, 255, .7);
  //background-color: #f2f6fc!important;
  //background-color: #f7f9fe;
  background-color: transparent;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.footer-font {
  font-size: 14px;
  //color: white;
  color: #fff;
}

.footer-top {
  margin: 10px 0;
}

.two-btn {
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userProtocol {
  margin: 0;
  padding: 0;

  &:hover {
    color: @main-theme-color;
  }
}

.usageSpecifications {
  &:hover {
    color: @main-theme-color;
  }
}


.field {
  //height: 450px;
  //height: 100vh;
  margin-top: 60px;
  padding: 16px 50px 50px;
  overflow: auto;
  color: #4c4948;
  font-size: 16px;

  ol, ul {
    list-style-type: decimal;
  }

  h4 {

    font-size: 18px;
    margin: 10px 0;
  }

  p {
    text-indent: 2em;
  }
}

.field li {
  margin-left: 30px;
  list-style-position: inside; /* 或 outside，根据你想要的标志符位置 */
}

.btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
}

.word-tip {

}

.modal-style {
  .ant-modal {
  }

  //.ant-modal{
  //  top: -60px!important;
  //}
  width: 800px !important;
  height: calc(100vh - 250px);
}

.ipc-style {
  margin-left: 15px;

  &:hover {
    cursor: pointer;
    color: @main-theme-color;
  }
}

.my-in {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: .3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: flex;
  }
}

.duojiyun {
  &:hover {
    fill: @main-theme-color;
    cursor: pointer;
  }
}

.logo-style-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-bottom {
  position: absolute;
  bottom: 0;
  background-size: 300% 300% !important;
  animation: gradientBG 10s ease infinite;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: linear-gradient(-90deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 760px) {

  .footer-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .two-btn {
    margin: 2px 0;
  }
}
