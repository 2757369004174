.main-box{
  border:1px solid red;
}
.header{
  //height: 50px;
  padding: 15px 0;
  //border:1px solid red;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}