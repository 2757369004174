.line-chart{
  padding-top: 15px;
  &>div{
    width: 100% !important;
    canvas{
      width: 100% !important;
    }
  }
  width: 100% !important;
  height: 100% !important;
}