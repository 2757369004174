.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 25px;
  width: 25px;
  mask: radial-gradient(circle calc(25px / 2 - 5px), transparent 99%, #2086ee 100%);
  background-image: conic-gradient(transparent 25%, #2d0cf6);
  animation: spins 0.9s linear infinite;
  border-radius: 50%;
}
@keyframes spins {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
