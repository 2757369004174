.mainBox {
  border: 1px solid red;

  margin-top: 60px;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100vh - 60px);

  .contentBox {
    width: 100%;
    max-width: 1400px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .contentL {
      display: flex;
      justify-content: space-between;
      border: 1px solid red;
      width: 60%;
      height: 100%;

      .title {
        border: 1px solid red;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        font-size: 20px;
        font-weight: bold;
        color: #4c4948;
      }

      .completed {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
      }

      .incomplete {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
      }

      :global .ant-checkbox-group {
        flex-direction: column;
        width: 100%;

        label {
          padding: 8px 16px;
          width: 100%;
          border: 1px solid red;
        }
      }
    }

    .incompleteUl {
      width: 100%;

      li {
        padding: 8px 16px;
        border: 1px solid red;
        color: gray; // 设置字体颜色为灰色
        text-decoration: line-through; // 添加删除线
        font-style: italic; // 添加倾斜样式
      }
    }

    .contentR {
      width: 40%;
      background: pink;
      height: 100%;
    }
  }
}

.mainContent {
  width: 100%;
  height: calc(100vh - 120px);
}