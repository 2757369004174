* {
  box-sizing: border-box;
}
.main-box {
  width: 98%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.rankOutBox {
  border: 1px solid red;
  position: relative;
  width: 48%;
}
.topRank {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}
.headerTop {
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
}
.headerTop span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottomItem {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.nameStyle {
  position: absolute;
  bottom: -15px;
  background-color: white;
  border-radius: 8px;
  padding: 2px 8px;
  color: #ffa116;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.oneRank {
  animation-delay: 0s;
  background: linear-gradient(#feea88, #d7a807);
}
.twoRank {
  margin-top: 15px;
  animation-delay: 2s;
  /* 延迟2秒开始 */
  background: linear-gradient(white, #ababab);
}
.threeRank {
  margin-top: 15px;
  animation-delay: 4s;
  /* 延迟2秒开始 */
  background: linear-gradient(#facfa3, #d77c44);
}
.oneRank,
.twoRank,
.threeRank {
  animation: 3s ease-in-out infinite floating;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.oneRank:hover,
.twoRank:hover,
.threeRank:hover {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
}
.oneRank img,
.twoRank img,
.threeRank img {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 50%;
  text-align: center;
  background: rgba(255, 255, 255, 0.4);
  color: #373737;
  font-size: 40px;
  opacity: 0.75;
}
.bottomRank {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  width: 100%;
  margin-top: 15px;
  transition: all 0.3s ease-in-out;
}
.bottomRank:hover {
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
}
.bottomRank .bottomRankUl {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.bottomRank .bottomRankUl .bottomRankLi {
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  padding: 8px 15px;
  border-bottom: 2px dashed #ecf0f1;
}
.bottomRank .bottomRankUl .bottomRankLi:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.bottomRank .bottomRankUl .bottomRankLi:hover {
  transition: all 0.3s ease-in-out;
  background-color: #ecf0f1;
}
.showMore {
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: all 0.3s ease-in-out;
  flex-grow: 1;
}
.showMore:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: #ecf0f1;
}
@keyframes floating {
  0% {
    transform: translateY(-4px);
  }
  50% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(-4px);
  }
}
.scoreStyle {
  font-size: 18px;
  color: #0e83f8;
  font-weight: bold;
}
.mapStyle {
  display: flex;
  width: 100%;
  position: absolute;
  left: -25px;
  top: 0px;
  font-size: 20px;
  font-weight: 600;
  color: #bdc3c7;
}
.mapStyle img {
  width: 62px;
  height: 30px;
}
.iconStyle:hover {
  color: #2e51b3;
}
.nickStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nickStyle img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.bottomStyle {
  font-size: 12px;
  color: #4c4948;
}
.nickNameStyle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: calc(100% - 50px);
}
.tableStyle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.tableStyle img {
  margin-right: 5px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.tip {
  margin-top: 5px;
  font-size: 12px;
  color: #4c4948;
  margin-bottom: 30px;
}
.tableStyle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nameWrapper {
  flex: 1;
  /* 确保内容占据剩余空间 */
  min-width: 0;
  /* 防止 flex 容器溢出 */
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 5px;
}
.nameText {
  white-space: nowrap;
  /* 禁止换行 */
  overflow: hidden;
  /* 隐藏溢出内容 */
  text-overflow: ellipsis;
  /* 显示省略号 */
  max-width: 100%;
  /* 限制最大宽度 */
}
.showMoreBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  background-color: white;
  transition: all 0.3s ease-in-out;
}
.showMoreBtn:hover {
  background-color: #fafafa;
  transition: all 0.3s ease-in-out;
}
.showBtnTextStyle {
  transition: all 0.3s ease-in-out;
  color: blue;
}
.showBtnTextStyle:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #2e51b3;
}
.outBox {
  margin: 30px 0 20px;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
}
.outBox:hover {
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
}
.switchStyle {
  width: 120px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.switchBtnStyle {
  display: flex;
  color: blue;
  justify-content: center;
  align-items: center;
}
.switchBtnStyle:hover {
  cursor: pointer;
  color: #2e51b3;
}
