.mainBox {
  width: 100%;
  height: 100vh;
  background: #f1f3f6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.iconStyle {
  width: 200px;
  height: 200px;
}
.backBtn {
  color: #2e51b3;
  text-decoration: underline;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.backBtn:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #2e51b3;
}
.tip {
  margin: 15px 0;
  font-size: 16px;
}
