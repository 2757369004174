@import "../../../../../styles/global.less";
.main-box {
  padding-top: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  .content {
    width: 100%;
    max-width: 1200px;

    .contentUl {
      width: 100%;
      //height: 100px;
      padding: 15px 0;
      display: flex;
      justify-content: space-around;

      .contentLi {
        width: 33%;
        display: flex;
        justify-content: center;
        align-items: center;

        .liLeft {
          margin-right: 10px;
          width: 100px;
          height: 100px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .liRight {

          width: 180px;
          height: 100%;
          //margin-bottom: 15px;
        }
      }
    }
  }
}

.topStyle {
  display: flex;
  align-items: flex-end;
}

.rateStyle {
  font-size: 40px;
  font-weight: bold;
  display: flex;
  margin-right: 5px;
  align-items: flex-end;

}
.rateStyleLittle {
  font-size: 18px;
  height: 100%;
  display: flex;
  padding-bottom: 5px;
  margin:0 3px;
}

.bottomStyle {
  margin-top: 15px;
  font-size: 20px;
  height: 50px;
}

.topScoreStyle {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0 50px 0;
}

.myScore {
  font-family: 'make', sans-serif !important;
  font-size: 60px;
  display: flex;
  justify-content: flex-end;

}
.back{
  &:hover{
    transition: all .3s ease-in-out;
    background-color: @main-theme-color;
    cursor: pointer;
    color: white;
  }
  border-radius: 12px;
  display: flex;
padding: 5px 8px;
  justify-content: center;

  align-items: center;
  margin: 15px 0;
  transition: all .3s ease-in-out;
}
.tipStyle{
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}