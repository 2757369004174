.mainBox{

  height: calc(100vh - 90px);
.headerTop{
  height: 60px;
  border:1px solid red;
  display: flex;
  align-items: center;

}
}