.main-box {
  border: 1px solid red;
  width: 100%;
  height: 100%;
}
.main-box .headerTop {
  border: 1px solid red;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
