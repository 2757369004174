@import "../../../../styles/global.less";

.mainBox {
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
overflow: auto;
  &::-webkit-scrollbar {
    width: 0; /* 隐藏横向滚动条 */
    height: 0; /* 隐藏纵向滚动条 */
    background: transparent; /* 背景透明 */
  }
}

.content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  position: relative;

}

.info {
  position: absolute;
  left: 0;
  right: 0;
  width: 300px;
  z-index: 10;

}

.contentLeft {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  //height: calc(100vh - 80px);
}

.tipContent {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contentRight {
  width: calc(100% - 300px);
  padding: 0 15px 0 50px;
  display: flex;
  flex-direction: column;
}

.contentUl {

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;


  .contentLi {
    margin: 12px 5px;
    background-color: white;
    transition: all .3s ease-in-out;
    //padding: 15px 5px;
    svg path {
      transition: all .3s ease-in-out;
    }

    &:hover {
      transform: translateY(-5px) scale(1.05);
      box-shadow: 0 4px 10px #00adff4d;
      cursor: pointer;

      * {
        color: white !important;
      }

      background: @main-theme-color;
      transition: all .3s ease-in-out;

      svg path {
        fill: white;
        transition: all .3s ease-in-out;
      }

    }

    border-radius: 12px;
    width: 150px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .liRight {
      padding: 3px 0;
      width: calc(100% - 50px);
      height: 100%;


      span {
        display: inline-block;
      }

      .subTilte {
        font-size: 12px;
      }
    }
  }
}

.singleUl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  .singleLi {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    min-width: 180px;

    .singleLiLeft {
      width: 50px;
      min-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;

      img {
        width: 45px;
        height: 45px;
      }
    }

    height: 100px;
    margin: 10px 12px;
    padding: 8px;
    border-radius: 12px;
    transition: all .3s ease-in-out;

    &:hover {
      transform: translateY(-5px) scale(1.05);
      box-shadow: 0 4px 10px #00adff4d;

      * {
        color: white !important;
      }

      cursor: pointer;
      transition: all .3s ease-in-out;

      background-color: @main-theme-color;
    }
  }
}

.singleLiLoading {
  margin: 10px 12px;
  border-radius: 12px;
}

.title {
  font-size: 22px;
  font-weight: bold;
  color: #4c4948;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0;

  svg {
    width: 35px;
    height: 35px;
    margin-right: 5px;
  }
}

.tilte {
  font-size: 16px !important;
  font-weight: bold;
  color: #4c4948;
}

.subTilte {
  font-size: 12px !important;
  margin: 5px 0 5px 0;
  color: #786f6f;
}

:global .ant-modal {
  width: 60% !important;
}

.modalDiv {
  width: 100% !important;
  height: 400px;
  display: flex;
  flex-direction: column;
  //div{
  //  margin: 10px 0;
  //  label{
  //    margin: 5px;
  //  }
  //}
}

.startBtn {
  display: block;
  margin: 0 auto;
}

.itemStyle {
  margin: 15px 0;
  display: flex;
  align-items: center;
}

.typeStyle {
  min-width: 120px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

:global .ant-checkbox-group {
  display: flex;

  label {
    &:first-child {
      margin-left: 0;
    }

    margin: 15px;
  }
}

.questionInfo {
  //background-color: white;
  overflow: hidden;
  border-radius: 12px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  //height: 500px;

  .questionInfoUl {
    border-radius: 12px;
    background-color: white;
    width: 48%;
    display: flex;
    flex-wrap: wrap !important;

    .questionInfoLi {
      padding-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;


      .titleLi {
        font-size: 18px;
        color: #656a6a;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          path {
            transition: all .3s ease-in-out;
          }

          &:hover {
            path {
              transition: all .3s ease-in-out;
              fill: @main-theme-color;
            }
          }

          margin-left: 3px !important;
          width: 20px;
          height: 20px;
        }
      }

      .valueLi {
        font-family: 'make', sans-serif !important;
        font-size: 36px;
        padding: 15px 0;
        color: @main-theme-color;
      }
    }


  }
}

.tip {
  //padding-left: 5px;
  //background-color: white;
  //border-radius: 8px;
  //margin: 10px 0;
  //display: flex;
  //justify-content: flex-start;
  //align-items: center;
}

.tipLiSmall {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
}

.tipLiBig {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
}

.tipUl {
  width: 445px;
  display: flex;
  flex-wrap: wrap;

  li {
    padding: 5px 0;
  }
}

.chao {
  //margin-top: 10px;
  width: 100%;
}

.rBox {
  width: 48%;
  border-radius: 12px;
  background-color: white;
}

.tipRateUl {
  width: 160px;
  display: flex;

  flex-wrap: wrap;

  li {
    margin: 5px 0;
  }
}

.rateBig {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rateSmall {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  color: @main-theme-color;
  font-weight: bold;
}

.tipScoreUl {
  width: 160px;
  display: flex;

  flex-wrap: wrap;

  li {
    margin: 5px 0;
  }
}

.scoreBig {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scoreSmall {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  color: @main-theme-color;
  font-weight: bold;
}

.hightValue {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
  font-size: 20px !important;
  font-weight: bold;
  color: @main-theme-color;
}

.topTitle {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 15px;
  align-items: center;

  .infoR {
    padding-left: 10px;
    width: calc(100% - 90px);
    height: 100% !important;

    .nickName {
      font-size: 22px;
      font-weight: bold;
      color: #4c4948;
    }
  }

  img {
    border-radius: 8px;
    width: 90px;
    height: 90px;
  }
}

//.newTabs {
//  :global .ant-tabs-nav-list {
//    margin-top: 120px !important;
//
//  }
//}

.myTabs {
  max-width: 1200px;
  width: 100%;
  height: calc(100vh - 100px);
:global .ant-tabs-content-holder{
  border: none;
}
  :global .ant-tabs-nav-list {
    width: 300px;
    margin-top: 215px;
    transition: margin-top .3s ease-in-out !important;
    .ant-tabs-ink-bar {
      visibility: hidden;
    }
    .ant-tabs-tab {
      display: flex;
      justify-content: flex-start;
      padding-left: 50px;
      align-items: center;
      background-color: white;
      font-size: 18px;
      font-weight: bold;
      color: #4c4948;
      border-right: none!important;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      .ant-tabs-tab-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        svg{

          margin-right: 15px;
        }
      }
    }

    .ant-tabs-tab-active {
      transition: all .3s ease-in-out;
      background-color: @main-theme-color;
      .ant-tabs-tab-btn{
        svg{
        path{transition: all .3s ease-in-out;
          fill: white;
        }
        }
      }

      * {
        transition: all .3s ease-in-out;
        color: white !important;
      }
    }
  }

  :global .ant-tabs-content-holder {


  }
}

.lv {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  font-family: "cuteFont", sans-serif;
  color: white;
  font-size: 14px;
  margin-right: 10px;
  border-radius: 3px 12px 12px 12px;
  background-color: #59e476;
}

.lvBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .tagBox {
    display: flex;
    margin-left: 15px;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 0 3px;
    border-radius: 5px;
    background-color: @main-theme-color;

  }

  .proBox {

    width: 90%;
    display: flex;

    .progressBox {
    }

  }
}

.tipBox {
  font-size: 12px !important;
  color: #73767c;
}

.signIn {
  margin: 15px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: url("https://www.mianshiya.com/_next/image?url=%2Fassets%2Fimages%2Fvip%2FnormalCard.png&w=750&q=75");
  background-size: cover;
  background-position: center;
  height: 80px;
  border-radius: 8px;

  .signL {
    padding: 15px;
    width: 65%;

    .signTilte {
      font-size: 22px;
      color: #4c4948;
    }

    .signContent {
      font-size: 12px;
      color: #857c7c;
    }
  }

  .signR {
    width: 35%;
  }
}

.prBox {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;

  svg {
    transition: all .3s ease-in-out;

    &:hover {
      cursor: pointer;
      transition: all .3s ease-in-out;

      path {

        fill: @main-theme-color;

      }
    }
  }
}

.signStyle {
  margin: 0 8px;
  padding: 5px 3px;
  font-weight: 700;
  border-radius: 9999px;
  background-color: #43403a;
  border: 2px solid #ede9e6;
  color: #ffecba;
  cursor: pointer;
  transition: opacity .3s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: .8;
  }
}
.lvIcon{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.rangListBox{
  display: flex;

  justify-content: flex-start;
  align-items: center;
}