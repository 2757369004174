.main-content-word {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: #f7f9fe;
}
.blog-item-box {
  margin-top: -0.714rem;
  animation: sild-in-blog 0.5s ease-in-out;
  background-color: #f7f9fe !important;
  position: relative;
  width: 100%;
  margin-bottom: 1.071rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: none;
  z-index: 9;
  border-radius: 0.857rem;
}
.blog-item-box .main-content {
  width: 100%;
  max-width: 96.429rem;
  display: flex;
  justify-content: space-around;
  padding: 1.429rem 1.071rem;
}
.blog-item-box .main-content .main-content-l {
  padding: 2.286rem 2.857rem;
  width: 76%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: white;
  border-radius: 0.857rem;
}
.blog-item-box .main-content .main-content-r {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  width: 23%;
}
.blog-item-box .main-content .main-content-r :global .ant-card-body {
  padding: 1.071rem 0.714rem;
}
.editor-style {
  width: 100%;
}
.comment-tip {
  width: 100%;
  margin: 2.143rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.571rem;
  background-color: white;
}
.comment-tip .blog-tip-box {
  border-radius: 0.357rem;
  width: 71.429rem;
  box-shadow: 0 0 0 0.071rem #a9d5de inset, 0 0 0 0 transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 550;
  color: #0e566c;
  font-size: 1.429rem;
  height: 5.286rem !important;
}
.comment-tip .blog-tip-box .login-btn {
  cursor: pointer;
}
.comment-tip .blog-tip-box .login-btn:hover {
  text-underline: black;
  cursor: pointer;
}
.top-comment-box {
  border-radius: 12px;
  position: relative;
  width: 100%;
  background-color: #f7f9fe;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 45px;
}
.hr-style {
  margin: 1.071rem 0;
  position: relative;
  width: 100%;
  border: 0.143rem dashed rgba(57, 197, 187, 0.5) !important;
}
.hr-style::before {
  position: absolute;
  top: -0.714rem;
  left: 5%;
  z-index: 1;
  color: rgba(57, 197, 187, 0.5);
  content: url("./img/snow.svg");
  font-size: 1.429rem;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
.avatar-style {
  width: 6rem;
  display: flex;
  height: 100% !important;
  justify-content: center;
  align-items: flex-start;
}
.avatar-style img {
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
}
.out-banner {
  width: 100%;
  height: 28.571rem;
  overflow: hidden;
  position: relative;
}
.out-banner .blog-wave {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
  align-items: flex-start;
  z-index: 10;
  padding-left: 5%;
}
.out-banner .blog-wave .headerMessage {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  opacity: 0.7;
}
.out-banner .blog-wave .headerMessage span {
  padding: 0.214rem 0.357rem;
  color: white;
  background: rgba(255, 255, 255, 0.15);
  font-weight: 550;
  border-radius: 0.357rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.714rem;
}
.blog-message {
  transition: all 0s ease;
  border: 0.071rem solid red;
  overflow: hidden;
  transform: rotate(10deg) translateY(5%) scale(2) translateZ(0);
  filter: blur(5px);
  opacity: 0.5;
  position: relative;
  width: 100%;
  height: 34.286rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff !important;
}
.blog-message img {
  position: absolute;
  top: 0;
  width: 100% !important;
  height: 100% !important;
}
.articleTag-style {
  display: flex;
  color: white;
  opacity: 0.7;
  flex-wrap: wrap;
  font-size: 1.286rem !important;
}
.confirmSend {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-textarea {
  background-image: url("./img/cute.png");
  background-repeat: no-repeat;
  background-position: right 5px bottom 5px;
  /* 将背景图放在右下角 */
  background-size: 100px 100px;
  /* 设置背景图宽高为 50px */
  font-size: 1.143rem;
  width: 100%;
  resize: none;
  background-color: #fff;
}
.custom-textarea:hover {
  background-color: #fff;
}
.custom-textarea:focus {
  background-color: #fff;
}
.comment-main {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.empty-out-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 1.071rem;
  margin-bottom: 1.071rem;
}
.blog-tilte {
  color: #fff;
  font-size: 2.286rem;
  margin: 1.071rem 0;
  font-weight: 700;
}
.tag {
  list-style: none;
  display: flex;
}
.tag li {
  background: rgba(255, 255, 255, 0.15);
  margin-right: 0.357rem;
  border-radius: 0.357rem;
  padding: 0.214rem 0.357rem;
  transition: all 0.3s ease-in-out;
}
.tag li:hover {
  background: #39c5bb;
  transition: all 0.3s ease-in-out;
  color: white !important;
  cursor: pointer;
  opacity: 1 !important;
}
.tag li:hover svg path {
  transition: all 0.3s ease-in-out;
  fill: white;
}
.edit-out-box {
  width: 71.429rem !important;
  height: calc(100ch - 18.571rem);
}
.top-word-style {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.857rem;
  color: #2e51b3;
  margin-left: 0.357rem;
}
.Topping {
  width: 100%;
}
.cancel-topping {
  margin-left: 1.071rem;
  cursor: default;
}
.cancel-topping:hover {
  cursor: pointer;
}
.my-emoji-child {
  margin-right: 0.357rem;
}
.all-background-ge {
  background: #fff;
}
.ql-editor li::before {
  content: "" !important;
}
.ql-editor img {
  max-width: 90%;
}
.header-blog {
  display: flex;
  flex-direction: column;
  width: 98%;
}
.header-blog .header-top {
  background-color: white;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.071rem;
  align-items: center;
  height: 3.214rem;
  color: #c1c1c1;
}
.header-blog .header-title {
  justify-content: flex-start;
  align-items: center;
  padding: 0.357rem 1.786rem 0.357rem 1.071rem;
  display: inline-flex;
  /* 使用 inline-flex 确保根据内容宽度自适应 */
  text-align: center;
  line-height: 2.571rem;
  background: #1e80ff;
  border-radius: 0 0 2.857rem 0;
  /* 将右下角设为 40px 半径，其他角为 0 */
  color: #fff;
  font-weight: 600;
  font-size: 1.286rem;
  letter-spacing: 0.071rem;
  height: 2.571rem;
}
.my-Mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden !important;
  z-index: -1;
}
.my-Mask :global #tsparticles {
  height: 100% !important;
}
.myStyleOnly {
  width: 98% !important;
  line-height: 210%;
  overflow: auto;
  color: #4c4948 !important;
}
.myStyleOnly table {
  border-radius: 12px !important;
  border: 1px solid #e3e8f7 !important;
}
.myStyleOnly table th {
  background: #f7f7f9;
  font-size: 16px;
}
.myStyleOnly pre {
  background: #272822 !important;
  padding-top: 3.214rem !important;
}
.myStyleOnly :global .toolbar {
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  top: 0 !important;
  right: 0 !important;
  background: #21252b;
  padding: 0.214rem 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.myStyleOnly :global .toolbar span {
  background: transparent !important;
}
.myStyleOnly :global .toolbar:before {
  content: " ";
  position: absolute;
  border-radius: 50%;
  width: 1.071rem;
  height: 1.071rem;
  top: 0.857rem;
  left: 0.857rem;
  background: #ff5f56;
  box-shadow: 1.429rem 0 #ffbd2e, 2.857rem 0 #27c93f;
  z-index: 3;
}
.myStyleOnly strong {
  color: #fff !important;
  padding: 0.214rem 0.357rem;
  margin: 0 0.143rem;
  background: #39c5bb;
  box-shadow: 0 0.571rem 1.143rem -0.286rem rgba(44, 45, 48, 0.047);
  border-radius: 0.286rem;
}
.myStyleOnly strong span {
  color: white !important;
}
.myStyleOnly table {
  margin: 0.714rem 0;
  width: 100% !important;
}
.myStyleOnly hr {
  background: #cbcbd5;
  margin: 0.357rem 0;
}
.myStyleOnly :global .toolbar {
  opacity: 1 !important;
}
.myStyleOnly :global .toolbar-item span {
  font-size: 1.143rem !important;
}
.myStyleOnly :global .toolbar-item span:hover {
  color: #bbb !important;
}
.myStyleOnly strong {
  font-weight: 550;
}
.myStyleOnly h1 {
  margin: 1.071rem 0 0.714rem;
}
.myStyleOnly h2 {
  margin: 1.071rem 0 0.571rem;
}
.myStyleOnly h3 {
  margin: 0.714rem 0 0.429rem;
}
.myStyleOnly h4 {
  margin: 0.429rem 0;
}
.myStyleOnly h5 {
  margin: 0.357rem 0;
}
.myStyleOnly h6 {
  margin: 1.429rem 0 1.071rem;
}
.myStyleOnly h1,
.myStyleOnly h2,
.myStyleOnly h3,
.myStyleOnly h4,
.myStyleOnly h5,
.myStyleOnly h6 {
  position: relative;
}
.myStyleOnly h1 strong,
.myStyleOnly h2 strong,
.myStyleOnly h3 strong,
.myStyleOnly h4 strong,
.myStyleOnly h5 strong,
.myStyleOnly h6 strong {
  color: #fff !important;
}
.myStyleOnly ul li,
.myStyleOnly ol li {
  position: relative;
  padding-left: 1.071rem;
}
.myStyleOnly ul li *,
.myStyleOnly ol li * {
  font-size: inherit !important;
}
.myStyleOnly ul li::after,
.myStyleOnly ol li::after {
  padding-top: 0;
  content: url("./img/circle.svg");
  position: absolute;
  left: 0;
  top: 0;
  color: inherit !important;
}
.myStyleOnly ul li span,
.myStyleOnly ol li span {
  color: #2e51b3;
}
.myStyleOnly h1 {
  color: #f4ea2a;
  font-size: 2.286rem !important;
  padding-left: 1.786rem;
}
.myStyleOnly h1 * {
  font-size: 2.286rem !important;
}
.myStyleOnly h1::after {
  width: 0.714rem;
  content: url("./img/H1Icon.svg");
  position: absolute;
  top: 0;
  left: 0;
  color: inherit !important;
}
.myStyleOnly h2 {
  font-size: 1.714rem !important;
}
.myStyleOnly h2 * {
  font-size: 1.714rem !important;
}
.myStyleOnly h3 {
  font-size: 1.337rem !important;
  position: relative;
  color: #FF7C24;
}
.myStyleOnly h3 * {
  font-size: 1.337rem !important;
  color: #FF7C24 !important;
}
.myStyleOnly h4 {
  font-size: 1.143rem !important;
  position: relative;
  padding-left: 1.286rem;
  color: #FF7C24;
}
.myStyleOnly h4 * {
  font-size: 1.143rem !important;
  color: #FF7C24 !important;
}
.myStyleOnly h4::after {
  content: url("./img/H4Icon.svg");
  position: absolute;
  top: 0.143rem;
  left: 0;
  color: inherit !important;
}
.myStyleOnly h5 {
  font-size: 0.949rem !important;
}
.myStyleOnly h5 * {
  font-size: 0.949rem !important;
}
.myStyleOnly h6 {
  font-size: 1.429rem !important;
  background: #d4d7f1;
  padding: 0.5rem 0;
  position: relative;
  text-indent: 3.071rem;
  color: #2e51b3;
  border-radius: 0.214rem;
  overflow: hidden;
}
.myStyleOnly h6 * {
  font-size: 1.286rem !important;
}
.myStyleOnly h6::after {
  text-indent: 0;
  width: 1.429rem;
  content: url("./img/LED.svg");
  position: absolute;
  top: 0.714rem;
  left: 1.071rem;
  color: inherit !important;
}
.myStyleOnly h6::before {
  content: "";
  width: 0.429rem;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  background: #2e51b3;
  z-index: 9;
  color: inherit !important;
}
.myStyleOnly h2 {
  position: relative;
  padding-left: 1.786rem;
  color: #2e51b3;
}
.myStyleOnly h2::after {
  content: url("./img/H2Icon.svg");
  position: absolute;
  top: 0.143rem;
  left: 0;
  color: inherit !important;
}
.banner-mask:before {
  content: "";
  width: 100%;
  height: 25rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
}
@media screen and (max-width: 760px) {
  .top-comment-box {
    overflow: hidden;
  }
}
@keyframes sild-in-blog {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes sild-in-header {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@media screen and (max-width: 1200px) {
  .main-content {
    padding: 0 !important;
  }
  .main-content-l {
    width: 100% !important;
    padding: 0 0.357rem !important;
    background: #f7f9fe !important;
  }
  .main-content-l .abstract {
    margin-top: 15px;
  }
  .main-content-r {
    display: none !important;
  }
}
.abstract {
  padding: 3.571rem 1rem 1rem;
  line-height: 150%;
  /* 设置行间距为字体大小的 150% */
  width: 98%;
  background: #f7f7f9;
  border-radius: 0.357rem;
  position: relative;
  color: #6f42c1;
  font-weight: 550;
  margin-bottom: 1.071rem;
}
.abstract .tag-l {
  top: 1rem;
  left: 1rem;
  color: white;
  opacity: 0.9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.abstract .tag-l .author {
  font-size: 0.857rem;
  text-align: center;
  padding: 0.071rem 0.357rem 0.071rem 0;
  border-radius: 0.214rem;
  line-height: 1.071rem;
  color: white;
  background-image: linear-gradient(to right, #f46b45, #eea849);
}
.abstract .tag-r {
  width: 2.143rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.286rem;
  top: 1rem;
  right: 0.714rem;
  font-size: 0.857rem;
  color: white;
  background: #2e51b3;
  padding: 0.071rem 0.143rem;
  border-radius: 0.214rem;
}
.link {
  width: auto!important;
  transition: all 0.3s ease-in-out;
  color: #4283e3;
  text-align: center;
  display: inline-block;
  border-bottom: 0.143rem solid #4283e3;
}
.link:hover {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border-color: red;
  color: red;
}
.link:hover svg path {
  fill: red !important;
}
.difficulty {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.714rem;
}
.difficulty .tip {
  border-radius: 0.214rem;
  padding: 0.071rem 0.214rem;
  font-size: 0.929rem;
  color: #fff;
  margin-left: 0.357rem;
}
.tag-box {
  position: sticky;
  top: 1.429rem;
}
.headerInBox {
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.headerStyle {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.571rem 0.714rem;
  color: white;
  background: #0a04e1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 0.357rem;
  border-top-right-radius: 0.357rem;
}
.rightStyle {
  width: calc(100% - 50px);
}
.reilef {
  width: 99%;
  border-left: 0.2rem solid #03a9f4;
  padding: 15px;
  background-color: #ecf7fe;
  border-radius: 8px;
  margin: 10px 0;
}
.reilef li {
  margin: 3px 0;
}
